<template>
  <div>
    <b-form-row v-if="getSelectedWidgetSettings">
      <b-col cols="12">
        <b-form-group>
          <b-form-checkbox
            id="notify-users"
            name="notify-users"
            :switch="widgetDetails.widgetCategoryID != 1"
            v-model="widgetDetails[getSelectedWidgetSettings].isNotified"
          >
            Widget Notification
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <template v-if="displayNotificationFrequency">
      <b-form-row>
        <b-col>
          <b-form-group>
            <h4 class="page-sub-header">Notification frequency</h4>
            <div class="floating-select-field">
              <b-form-select
                id="notification-frequency"
                class="form-control"
                text-field="frequency"
                value-field="value"
                :options="notificationFrequencyTypes"
                v-model="widgetDetails.notificationFrequencySetting.frequency"
                @input="widgetDetails.notificationFrequencySetting.frequency = $event"
                :class="{
                  'is-value-exist': widgetDetails.notificationFrequencySetting.frequency != null,
                  'is-invalid': !widgetDetails.notificationFrequencySetting.frequency && formSubmitted,
                }"
              >
              </b-form-select>
              <label for="notification-frequency">Notification frequency</label>
              <b-form-invalid-feedback class="d-block" v-if="!widgetDetails.notificationFrequencySetting.frequency && formSubmitted"
                >Notification frequency required.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row v-if="widgetDetails.notificationFrequencySetting.frequency === 'Weekly'">
        <b-col>
          <b-form-group>
            <div class="floating-select-field">
              <b-form-select
                id="notification-day"
                class="form-control"
                text-field="day"
                value-field="value"
                :options="dayOptions"
                v-model.number="widgetDetails.notificationFrequencySetting.day"
                @input="widgetDetails.notificationFrequencySetting.day = $event"
                :class="{
                  'is-value-exist': widgetDetails.notificationFrequencySetting.day != null,
                  'is-invalid': !widgetDetails.notificationFrequencySetting.day && widgetDetails.notificationFrequencySetting.day !== 0 && formSubmitted,
                }"
              >
              </b-form-select>
              <label for="notification-frequency">Notification day</label>
              <b-form-invalid-feedback
                class="d-block"
                v-if="!widgetDetails.notificationFrequencySetting.day && widgetDetails.notificationFrequencySetting.day !== 0 && formSubmitted"
                >Notification day required.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row v-if="widgetDetails.notificationFrequencySetting.frequency === 'Daily' || widgetDetails.notificationFrequencySetting.frequency === 'Weekly'">
        <b-col>
          <b-form-group>
            <div class="floating-select-field">
              <b-form-select
                id="notification-time"
                class="form-control"
                text-field="zoneTimeInHour"
                value-field="value"
                :options="timeOptions"
                v-model.number="widgetDetails.notificationFrequencySetting.zoneTimeInHour"
                @input="widgetDetails.notificationFrequencySetting.zoneTimeInHour = $event"
                :class="{
                  'is-value-exist': widgetDetails.notificationFrequencySetting.zoneTimeInHour != null,
                  'is-invalid':
                    !widgetDetails.notificationFrequencySetting.zoneTimeInHour &&
                    widgetDetails.notificationFrequencySetting.zoneTimeInHour !== 0 &&
                    formSubmitted,
                }"
              >
              </b-form-select>
              <label for="notification-frequency">Notification time</label>
              <b-form-invalid-feedback class="d-block" v-if="!widgetDetails.notificationFrequencySetting.zoneTimeInHour && widgetDetails.notificationFrequencySetting.zoneTimeInHour !== 0 && formSubmitted"
                >Notification time required.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
    </template>
    <template v-if="widgetDetails.widgetCategoryID == 1 && widgetDetails[getSelectedWidgetSettings].isNotified">
      <b-form-row>
        <b-col>
          <b-form-group>
            <div class="floating-input-field">
              <b-form-textarea
                id="app-notification-text"
                placeholder=" "
                class="overflow-auto"
                rows="5"
                v-model="widgetDetails.contentNotificationTemplate.appMessage"
                required
                :class="{
                  'is-invalid': !widgetDetails.contentNotificationTemplate.appMessage && formSubmitted,
                }"
              ></b-form-textarea>
              <label for="app-notification-text">App notification text</label>
              <b-form-invalid-feedback class="d-block" v-if="!widgetDetails.contentNotificationTemplate.appMessage && formSubmitted"
                >App notification text required.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group>
            <div class="floating-input-field">
              <b-form-textarea
                id="email-notification-text"
                placeholder=" "
                class="overflow-auto"
                rows="5"
                v-model="widgetDetails.contentNotificationTemplate.emailMessage"
                required
                :class="{
                  'is-invalid': !widgetDetails.contentNotificationTemplate.emailMessage && formSubmitted,
                }"
              ></b-form-textarea>
              <label for="email-notification-text">Email notification text</label>
              <b-form-invalid-feedback class="d-block" v-if="!widgetDetails.contentNotificationTemplate.emailMessage && formSubmitted"
                >Email notification text required.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="10" md="6" lg="5" xl="4" class="d-flex justify-content-between">
          <b-button type="button" v-activeBlur variant="primary" @click="reset" class="btn-theme"> Reset </b-button>
        </b-col>
      </b-form-row>
    </template>
    <template v-if="widgetDetails.widgetCategoryID == 2 && widgetDetails[getSelectedWidgetSettings].isNotified">
      <b-form-row>
        <b-col class="d-flex mb-2">
          <div>
            <InfoCircelYellow class="mr-1 mt-n1" />
          </div>
          <div class="font-14">
            {{ DISPLAY_MESSAGES.CALENDAR_WIDGET_CATEGORY_DEPENDENT_INFO }}
          </div>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" sm="7" md="6" lg="4">
          <b-form-group>
            <b-form-checkbox
              v-model="widgetDetails[getSelectedWidgetSettings].isCategoryDependent"
              name="calendar-event-category-dependent-checkbox"
              :checked="widgetDetails[getSelectedWidgetSettings].isCategoryDependent"
            >
              Category dependent
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group>
            <h4 class="page-sub-header">Notify user</h4>
            <b-form-radio-group
              v-model="widgetDetails[getSelectedWidgetSettings].isOnPublishedDate"
              name="calendar-event-notifi-users"
              @change="onChangeCalendarNotifyUser"
              aria-describedby="Calendar event notify users"
              stacked
            >
              <b-form-radio class="calendar-notify-user-options" :value="true">On published date</b-form-radio>

              <div class="d-flex align-items-center">
                <b-form-radio class="calendar-notify-user-options" :value="false"></b-form-radio>
                <div class="d-flex align-items-center">
                  <div>
                    <b-form-select
                      id="calendar-event-notify-user-daysPriorToEventDate"
                      class="form-control"
                      :disabled="widgetDetails[getSelectedWidgetSettings].isOnPublishedDate"
                      v-model="widgetDetails[getSelectedWidgetSettings].daysPriorToEventDate"
                      @input="widgetDetails[getSelectedWidgetSettings].daysPriorToEventDate = $event"
                    >
                      <b-form-select-option v-for="days in getDaysPriorToEventDateRange" :key="days" :value="days">{{ days }}</b-form-select-option>
                    </b-form-select>
                  </div>
                  <div class="ml-1">days prior to event date</div>
                </div>
              </div>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-form-row>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { WidgetTypesSettings, DISPLAY_MESSAGES } from '@/utilities/constants'
import InfoCircelYellow from '@/assets/svg/info-circle-yellow.svg'
export default {
  name: 'Notifications',
  props: ['widgetDetails', 'isEditPage', 'formSubmitted'],
  components: {
    InfoCircelYellow,
  },
  data() {
    return {
      widgetTypesSettings: WidgetTypesSettings,
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      daysPriorToEventDateRange: 15,
      notificationFrequencyTypes: [
        { frequency: 'Hourly', value: 'Hourly' },
        { frequency: 'Daily', value: 'Daily' },
        { frequency: 'Weekly', value: 'Weekly' },
      ],
      dayOptions: [
        { day: 'Sunday', value: 0 },
        { day: 'Monday', value: 1 },
        { day: 'Tuesday', value: 2 },
        { day: 'Wednesday', value: 3 },
        { day: 'Thursday', value: 4 },
        { day: 'Friday', value: 5 },
        { day: 'Saturday', value: 6 },
      ],
      timeOptions: [
        { zoneTimeInHour: '00:00', value: 0 },
        { zoneTimeInHour: '01:00', value: 1 },
        { zoneTimeInHour: '02:00', value: 2 },
        { zoneTimeInHour: '03:00', value: 3 },
        { zoneTimeInHour: '04:00', value: 4 },
        { zoneTimeInHour: '05:00', value: 5 },
        { zoneTimeInHour: '06:00', value: 6 },
        { zoneTimeInHour: '07:00', value: 7 },
        { zoneTimeInHour: '08:00', value: 8 },
        { zoneTimeInHour: '09:00', value: 9 },
        { zoneTimeInHour: '10:00', value: 10 },
        { zoneTimeInHour: '11:00', value: 11 },
        { zoneTimeInHour: '12:00', value: 12 },
        { zoneTimeInHour: '13:00', value: 13 },
        { zoneTimeInHour: '14:00', value: 14 },
        { zoneTimeInHour: '15:00', value: 15 },
        { zoneTimeInHour: '16:00', value: 16 },
        { zoneTimeInHour: '17:00', value: 17 },
        { zoneTimeInHour: '18:00', value: 18 },
        { zoneTimeInHour: '19:00', value: 19 },
        { zoneTimeInHour: '20:00', value: 20 },
        { zoneTimeInHour: '21:00', value: 21 },
        { zoneTimeInHour: '22:00', value: 22 },
        { zoneTimeInHour: '23:00', value: 23 },
      ],
    }
  },
  computed: {
    getSelectedWidgetSettings() {
      const settings = this.widgetTypesSettings.find((setting) => setting.widgetCategoryID === Number(this.$route.params.widgetCategoryId))
      if (settings) {
        return settings.name
      }
      return null
    },
    getDaysPriorToEventDateRange() {
      const range = []
      for (let days = 0; days <= this.daysPriorToEventDateRange; days++) {
        range.push(days)
      }
      return range
    },
    displayNotificationFrequency() {
      if (this.widgetDetails.widgetCategoryID === 1 && this.widgetDetails[this.getSelectedWidgetSettings].isNotified) return true
      else if (this.widgetDetails.widgetCategoryID === 2) return false
      else if (this.widgetDetails.widgetCategoryID === 3 && this.widgetDetails[this.getSelectedWidgetSettings].isNotified) return true
      else if (this.widgetDetails.widgetCategoryID === 4 && this.widgetDetails[this.getSelectedWidgetSettings].isNotified) return true
      else if (this.widgetDetails.widgetCategoryID === 5 && this.widgetDetails[this.getSelectedWidgetSettings].isNotified) return true
      else if (this.widgetDetails.widgetCategoryID === 6 && this.widgetDetails[this.getSelectedWidgetSettings].isNotified) return true
      else if (this.widgetDetails.widgetCategoryID === 7 && this.widgetDetails[this.getSelectedWidgetSettings].isNotified) return true
      else if (this.widgetDetails.widgetCategoryID === 8) return false
      else if (this.widgetDetails.widgetCategoryID === 9 && this.widgetDetails[this.getSelectedWidgetSettings].isNotified) return true
      return false
    },
    ...mapState({
      contentNotificationTemplate: (state) => state.widgets.contentNotificationTemplate,
    }),
  },
  methods: {
    reset() {
      this.$nextTick(() => {
        this.widgetDetails.contentNotificationTemplate.appMessage = this.contentNotificationTemplate.appMessage
        this.widgetDetails.contentNotificationTemplate.emailMessage = this.contentNotificationTemplate.emailMessage
      })
    },
    onChangeCalendarNotifyUser(event) {
      this.widgetDetails[this.getSelectedWidgetSettings].daysPriorToEventDate = event ? null : 7
    },
  },
}
</script>
